import React from 'react';
import { Wheel } from 'react-custom-roulette';
import { useSpin } from '../context/SpinContext';
import { useFetchWheelData } from '../hooks/useFetchWheelData';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';

type WheelComponentProps = {
    spinRef: string;
};

const WheelComponent: React.FC<WheelComponentProps> = ({ spinRef }) => {
    const { data, isLoading } = useFetchWheelData(spinRef);
    const { mustSpin, setMustSpin, setHasFinishedSpinning, hasStartedSpinning, hasFinishedSpinning, setHasStartedSpinning } = useSpin();

    const handleSpinClick = () => {
        setMustSpin(true);
        setHasStartedSpinning(true);
    };

    if (isLoading) return <div>Loading...</div>;

    // Calculate total weight and normalized weights
    const totalWeight = data?.data?.wheel_weights?.reduce((a: number, b: number) => a + b, 0) || 0;
    
    // Ensure data format aligns with the new API structure
    const wheelData = data?.data?.wheel_items?.map((item: string, idx: number) => {
        const weight = data?.data?.wheel_weights[idx] || 1;
        
        // Balanced text truncation for perpendicular text
        const truncateForPerpendicular = (text: string) => {
            if (!text) return '';
            
            // Calculate segment percentage
            const segmentPct = weight / totalWeight;
            
            // More balanced character limit based on segment size
            let maxChars;
            
            if (segmentPct < 0.1) { // Very small segments (less than 10%)
                maxChars = 3; // Just enough for a small word or abbreviation
            } else if (segmentPct < 0.15) {
                maxChars = 5;
            } else if (segmentPct < 0.2) {
                maxChars = 8;
            } else if (segmentPct < 0.3) {
                maxChars = 12;
            } else {
                maxChars = 16; // More generous for larger segments
            }
            
            // If text already fits, return it as is
            if (text.length <= maxChars) return text;
            
            // Try to break at word boundaries for better readability
            const words = text.split(' ');
            let result = '';
            
            for (const word of words) {
                // Check if adding next word would exceed the limit
                if ((result + (result ? ' ' : '') + word).length <= maxChars) {
                    result += (result ? ' ' : '') + word;
                } else {
                    // If we can't fit another word, stop
                    break;
                }
            }
            
            // If no complete word fits or we're working with a very long single word
            if (result === '') {
                result = text.substring(0, maxChars - 1);
                return result + '…'; // Use a single ellipsis character to save space
            }
            
            // Add ellipsis only if truncation occurred and we have space
            if (result.length < text.length && result.length < maxChars - 1) {
                return result + '…'; // Use a single ellipsis character
            }
            
            return result;
        };

        return {
            id: idx,
            option: truncateForPerpendicular(item),
            fullOption: item, // Store the full text for result display
            optionSize: weight
        };
    }) || [];    
    
    const title = data?.data?.wheel_title || 'Loading...';
    const outcome = data?.data?.outcome || 0;

    return (
        <div className="container my-5">
            <div className="card shadow-lg">
                <div className="card-header d-flex justify-content-center align-items-center">
                    <h4 className='m-0'>{title}</h4>
                </div>

                <div className="card-body text-center">
                    <div className="d-flex justify-content-center my-4">
                        <Wheel
                            mustStartSpinning={mustSpin}
                            prizeNumber={outcome}
                            data={wheelData}
                            outerBorderColor="#f2f2f2"
                            outerBorderWidth={10}
                            innerBorderColor="#f2f2f2"
                            radiusLineColor="#dedede"
                            radiusLineWidth={2}
                            textColors={["#ffffff"]}
                            fontSize={16} // Moderate font size
                            perpendicularText={true} // Keep perpendicular text as requested
                            textDistance={75} // Move text further out for better fit
                            backgroundColors={["#942f54", "#d35e5f", "#e2b58c"]}
                            onStopSpinning={() => {
                                setMustSpin(false);
                                setHasFinishedSpinning(true);
                                
                                fetch(`https://seahorse-app-46qz2.ondigitalocean.app/viewed/${spinRef}`, {
                                    method: 'POST',
                                    headers: {
                                        'Accept': 'application/json, text/plain, */*',
                                        'Content-Type': 'application/json'
                                    },
                                    body: JSON.stringify({ a: 7, str: 'Some string: &=&' })
                                })
                                .then(res => res.json())
                                .then(res => console.log(res));
                            }}
                        />
                    </div>

                    <div className="row m-3 text-center">
                        {!hasStartedSpinning && !hasFinishedSpinning && (
                            <Button id="spinButton" size="lg" onClick={handleSpinClick}>Spin</Button>
                        )}
                        {hasStartedSpinning && !hasFinishedSpinning && (
                            <h4 className="px-0">Result: ?</h4>
                        )}
                        {hasStartedSpinning && hasFinishedSpinning && (
                            <h4 className="px-0">
                                <strong>Result:</strong> <span className="text-primary">{wheelData[outcome]?.fullOption || '?'}</span>
                            </h4>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WheelComponent;